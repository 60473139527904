import type { ISagaModule } from 'redux-dynamic-modules-saga';
import CarouselItemsService from '../../../../client/services/entities/CarouselItemsService';
import BaseStoreNamespaceEnum from '../../../../framework/base/common/constants/enums/BaseStoreNamespaceEnum';
import createEntitiesListSaga from '../../../../framework/base/common/store/sagas/factories/entity/createEntitiesListSaga';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import homeReducer from './homeReducer';
import type { IHomeAwareState } from './models/IHomeAwareState';

const homeModule: ISagaModule<IHomeAwareState> = {
  id: 'home',
  reducerMap: {
    home: homeReducer,
  },
  sagas: [
    createEntitiesListSaga(EntityTypeEnum.CarouselItem, CarouselItemsService, BaseStoreNamespaceEnum.Home),
  ],
};

export default homeModule;
