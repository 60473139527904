import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import EntityImageSizeEnum from '../../../../framework/base/common/constants/enums/EntityImageSizeEnum';
import type { WithoutNullableKeys } from '../../../../framework/base/common/constants/types/UtilityTypes';
import PathHelper from '../../../../framework/base/common/helpers/PathHelper';
import ApiRoutePaths from '../../../constants/path/ApiRoutePaths';
import type { CarouselItemListItemApiModel } from '../../../models/api/carouselItem/CarouselItemListItemApiModel';
import './HomePageCarousel.scss';

type CarouselItemWithImage = WithoutNullableKeys<Pick<CarouselItemListItemApiModel, 'image'>> & Omit<CarouselItemListItemApiModel, 'image'>;

interface HomePageCarouselProps {
  carouselItems: CarouselItemListItemApiModel[];
}

const HomePageCarousel: React.FC<HomePageCarouselProps> = ({ carouselItems }: HomePageCarouselProps) => {

  const data = useMemo<CarouselItemWithImage[]>(() => {
    const result: CarouselItemWithImage[] = [];

    for (const i of carouselItems) {
      if (i.image) {
        result.push({
          ...i,
          image: i.image,
        });
      }
    }

    return result;
  }, [carouselItems]);

  const [carouselItemActiveIndex, setCarouselItemActiveIndex] = useState(0);
  const [intervalTimeout, setIntervalTimeout] = useState<NodeJS.Timeout>();

  const stopAnimation = () => {
    if (intervalTimeout) {
      clearInterval(intervalTimeout);
      setIntervalTimeout(undefined);
    }
  };

  const startAnimation = useCallback(() => {
    if (data.length) {
      setIntervalTimeout(setInterval(() => {
        setCarouselItemActiveIndex(index => {
          return index === data.length - 1 ? 0 : index + 1;
        });
      }, 4000));
    }
  }, [data]);

  useEffect(() => {
    startAnimation();
  }, [startAnimation]);

  useEffect(() => () => {
    if (intervalTimeout) {
      clearInterval(intervalTimeout);
    }
  }, [intervalTimeout]);

  const handleOnClickNext = () => {
    if (data) {
      stopAnimation();
      setCarouselItemActiveIndex(index => {
        return index === data.length - 1 ? 0 : index + 1;
      });
      startAnimation();
    }
  };

  const handleOnClickPrev = () => {
    if (data) {
      stopAnimation();
      setCarouselItemActiveIndex(index => {
        return index === 0 ? data.length - 1 : index - 1;
      });
      startAnimation();
    }
  };

  const handleOnClickGoTo = (index: number) => {
    stopAnimation();
    setCarouselItemActiveIndex(index);
    startAnimation();
  };

  return (
    <div
      id='homeCarousel'
      className='carousel slide carousel-fade'
    >
      <div className='carousel-indicators'>
        {data.map((carouselItem, index) => {
          return (
            <button
              key={`${index}-${carouselItem.id}`}
              type='button'
              className={classNames({ active: index === carouselItemActiveIndex })}
              data-bs-target="#homeCarousel"
              aria-current={index === carouselItemActiveIndex ? 'true' : undefined}
              aria-label={`Slide ${index}`}
              onClick={() => handleOnClickGoTo(index)}
            />
          );
        })}
      </div>
      <div className='carousel-inner'>
        {data.map((carouselItem, index) => (
          <div
            key={index}
            className={classNames('carousel-item', { active: index === carouselItemActiveIndex })}
          >
            <div
              className='image-wrapper'
              style={{
                backgroundImage: `url(${PathHelper.getEntityImageUrl(ApiRoutePaths.IMAGES_PART_CAROUSEL_ITEM, carouselItem.id, carouselItem.image, EntityImageSizeEnum.Source)})`,
              }}
            />
            <div className='carousel-caption text-start'>
              {carouselItem.url && (
                <Link
                  to={carouselItem.url}
                >
                  <h1>{carouselItem.title}</h1>
                  {carouselItem.teaser && (
                    <p>{carouselItem.teaser}</p>
                  )}
                </Link>
              )}
              {!carouselItem.url && (
                <>
                  <h1>{carouselItem.title}</h1>
                  {carouselItem.teaser && (
                    <p>{carouselItem.teaser}</p>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <button
        className='carousel-control-prev'
        type='button'
        onClick={handleOnClickPrev}
      >
        <span
          className='carousel-control-prev-icon'
          aria-hidden='true'
        />
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        onClick={handleOnClickNext}
      >
        <span
          className='carousel-control-next-icon'
          aria-hidden='true'
        />
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  );
};

export default HomePageCarousel;
