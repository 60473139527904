import { combineReducers, Reducer, type AnyAction, type ReducersMapObject } from 'redux';
import BaseStoreNamespaceEnum from '../../../../framework/base/common/constants/enums/BaseStoreNamespaceEnum';
import type { BaseEntitiesQuery } from '../../../../framework/base/common/models/queries/base/BaseEntitiesQuery';
import type { EntitiesListState } from '../../../../framework/base/common/store/actions/models/state/entities/EntitiesListState';
import entitiesListReducerFactory from '../../../../framework/base/common/store/reducers/factories/entity/entitiesListReducerFactory';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import type { CarouselItemListItemApiModel } from '../../../models/api/carouselItem/CarouselItemListItemApiModel';
import type { HomeState } from './models/HomeState';

const carouselItemItems: Reducer<EntitiesListState<CarouselItemListItemApiModel, BaseEntitiesQuery> | undefined> = entitiesListReducerFactory<
  CarouselItemListItemApiModel,
  BaseEntitiesQuery
>(EntityTypeEnum.CarouselItem, BaseStoreNamespaceEnum.Home);

const profileAdvertsReducersMap: ReducersMapObject<HomeState, AnyAction> = {
  carouselItems: carouselItemItems,
};

const homeReducer: Reducer<HomeState, AnyAction> = combineReducers(profileAdvertsReducersMap);

export default homeReducer;
