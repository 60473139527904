import React, { useEffect, useRef } from 'react';
import DynamicModuleLoaderWrapper from '../../../../framework/base/common/components/shared/elements/DynamicModuleLoaderWrapper';
import BaseStoreNamespaceEnum from '../../../../framework/base/common/constants/enums/BaseStoreNamespaceEnum';
import useUrlGenerator from '../../../../framework/base/common/hooks/useUrlGenerator';
import type { BaseEntitiesQuery } from '../../../../framework/base/common/models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../../framework/base/common/models/queries/base/BaseEntitiesQueryFilters';
import useEntitiesListFromState, { EntitiesListFromStateReloadRef } from '../../../../framework/base/common/modules/shared/components/pages/entities/hooks/useEntitiesListFromState';
import createEntitiesListSelector from '../../../../framework/base/common/store/selectors/helpers/createEntitiesListSelector';
import EntityTypeEnum from '../../../constants/enums/EntityTypeEnum';
import AdvertsUrlQueryMapper from '../../../helpers/mappers/AdvertsUrlQueryMapper';
import homeModule from '../../../store/modules/home/homeModule';
import type { IHomeAwareState } from '../../../store/modules/home/models/IHomeAwareState';
import LoadingFadeContent from '../../shared/elements/LoadingFadeContent';
import HomePageCarousel from './HomePageCarousel';

const HomePage: React.FC = () => {

  const urlGenerator = useUrlGenerator<BaseEntitiesQuery<BaseEntitiesQueryFilters>, AdvertsUrlQueryMapper>(AdvertsUrlQueryMapper);

  const slidesRef = useRef<EntitiesListFromStateReloadRef>(null);

  const { data, isLoading } = useEntitiesListFromState({
    urlGenerator,
    entityType: EntityTypeEnum.CarouselItem,
    namespace: BaseStoreNamespaceEnum.Home,
    selector: createEntitiesListSelector((state: IHomeAwareState) => state?.home?.carouselItems),
    modifyQuery: undefined,
    innerRef: slidesRef,
  });

  useEffect(() => {
    if (slidesRef.current) {
      slidesRef.current.forceReloadFromState();
    }
  }, []);

  return (
    <DynamicModuleLoaderWrapper modules={[homeModule]}>
      <main className='position-relative'>
        <LoadingFadeContent isLoading={isLoading}>
          {data && (
            <HomePageCarousel
              carouselItems={data}
            />
          )}
        </LoadingFadeContent>
      </main>
    </DynamicModuleLoaderWrapper>
  );
};

export default HomePage;
