import ApiRoutePaths from '../../../common/constants/path/ApiRoutePaths';
import AdvertsQueryMapper from '../../../common/helpers/mappers/AdvertsQueryMapper';
import type { CarouselItemDetailApiModel } from '../../../common/models/api/carouselItem/CarouselItemDetailApiModel';
import type { CarouselItemListItemApiModel } from '../../../common/models/api/carouselItem/CarouselItemListItemApiModel';
import BaseEntitiesSearchService from '../../../framework/base/client/services/base/BaseEntitiesSearchService';
import type BaseEntitiesQueryMapper from '../../../framework/base/common/helpers/mappers/base/BaseEntitiesQueryMapper';
import type { BaseEntitiesQuery } from '../../../framework/base/common/models/queries/base/BaseEntitiesQuery';
import type { BaseEntitiesQueryFilters } from '../../../framework/base/common/models/queries/base/BaseEntitiesQueryFilters';

class CarouselItemsService extends BaseEntitiesSearchService<
  CarouselItemDetailApiModel,
  BaseEntitiesQueryFilters,
  BaseEntitiesQuery<BaseEntitiesQueryFilters>,
  BaseEntitiesQueryMapper,
  CarouselItemListItemApiModel
> {

  constructor() {
    super(ApiRoutePaths.CAROUSEL_ITEMS, new AdvertsQueryMapper());
  }
}

export default CarouselItemsService;
